import React, { createContext, useContext, useEffect, useState } from "react";
import fetch from "isomorphic-unfetch";
import { apiUrl } from "./config";
import { formatISO } from "date-fns";

// import Cookies from "js-cookie";

const LocalStateContext = createContext();
const LocalStateProvider = LocalStateContext.Provider;

// Then create a provider Component
function AppProvider({ children }) {
  const [snackOpen, setSnackOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [user, setUser] = useState(null);
  // const [token, setToken] = useState(null);
  const [data, setData] = useState(null);
  const [cart, setCart] = useState(null);
  const [alpha, setAlpha] = useState(null);
  const [orderInputs, setOrderInputs] = useState({
    firstName: "",
    lastName: "",
    address: "",
    zipcode: "",
    city: "",
    country: "Greece",
    email: "",
    phone: "",
    serialNumber: "",
    invoiceNumber: "",
    gadgetCode: "",
    productGroup: "", //katigoria
    price: "", //grossPrice
    cover: "", // proion
    purchaseDate: "",
    salesPoint: 0,
    agree: false,
  });

  const [notify, setNotify] = useState({
    place: "bc",
    message: "<div>{msg}</div>",
    type: "info",
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 60000,
  });

  useEffect(() => {
    console.log(`
    ██████╗ ██████╗  █████╗  ██████╗ ██████╗ ███████╗ ██████╗ ██████╗███╗  ██╗███████╗████████╗
    ██╔══██╗██╔══██╗██╔══██╗██╔════╝ ██╔══██╗██╔════╝██╔════╝██╔════╝████╗ ██║██╔════╝╚══██╔══╝
    ██████╔╝██████╔╝██║  ██║██║  ██╗ ██████╔╝█████╗  ╚█████╗ ╚█████╗ ██╔██╗██║█████╗     ██║
    ██╔═══╝ ██╔══██╗██║  ██║██║  ╚██╗██╔══██╗██╔══╝   ╚═══██╗ ╚═══██╗██║╚████║██╔══╝     ██║
    ██║     ██║  ██║╚█████╔╝╚██████╔╝██║  ██║███████╗██████╔╝██████╔╝██║ ╚███║███████╗   ██║
    ╚═╝     ╚═╝  ╚═╝ ╚════╝  ╚═════╝ ╚═╝  ╚═╝╚══════╝╚═════╝ ╚═════╝ ╚═╝  ╚══╝╚══════╝   ╚═╝   `);
    getApiData("v1/categories", "categories");
  }, []);

  const handleOrderInputsChange = (name, value) => {
    setOrderInputs({ ...orderInputs, [name]: value });
  };

  const snackNotify = (color, msg) => {
    let type;
    switch (color) {
      case 201:
        type = "info";
        break;
      case 200:
        type = "success";
        break;
      case 422:
        type = "error";
        break;
      case 401:
        type = "error";
        break;
      case 404:
        type = "warning";
        break;

      default:
        type = "info";
        break;
    }

    const options = {
      place: "bc",
      message: <div>{msg}</div>,
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 8000,
    };

    setNotify(options);
  };

  const callApi = async (url, method = "GET", payload = null) => {
    setLoading(true);
    let res;
    const methHeaders = {
      GET: {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
      },
      POST: {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...payload }),
      },
    }[method];
    try {
      await fetch(url, methHeaders)
        .then((response) =>
          response
            .json()
            .then((res) => ({ status: response.status, body: res }))
        )
        .then((myJson) => {
          res = myJson.body;

          if (myJson.status === 200) {
            if (myJson.body.message) {
              snackNotify(myJson.status, myJson.body?.message);
              setSnackOpen(true);
            }
            setLoading(false);
          } else {
            if (myJson.body.message) {
              snackNotify(myJson.status, myJson.body?.message);
              setSnackOpen(true);
            }
            setLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    return res;
  };

  const getApiData = async (endpoint, dataObj, method, payload) => {
    let response = null;
    const urlA = `${apiUrl}/${endpoint}`;

    const res = await callApi(urlA, method, payload);

    if (res?.length > 0) {
      setData({ ...data, [dataObj]: [...res] });
      response = res;
    }
    return response;
  };

  const handlePayment = async () => {
    let payload = {
      ...orderInputs,
    };
    payload.cover = cart.mainCover;
    payload.price = parseFloat(payload?.price);
    // payload.price = cart.grossPrice;
    payload.purchaseDate = formatISO(new Date(orderInputs.purchaseDate));

    const urlA = `${apiUrl}/v2/save`;

    const res = await callApi(urlA, "POST", payload);

    if (res) {
      console.log({ res });
      setAlpha(res);
    }
  };
  return (
    <LocalStateProvider
      value={{
        notify,
        snackOpen,
        snackNotify,
        setSnackOpen,
        loading,
        setLoading,
        cart,
        setCart,
        getApiData,
        callApi,
        data,
        orderInputs,
        setOrderInputs,
        handleOrderInputsChange,
        handlePayment,
        alpha,
        setAlpha,
      }}
    >
      {children}
    </LocalStateProvider>
  );
}

function useAppContext() {
  // We use a consumer here to access the local state
  return useContext(LocalStateContext);
}

export { AppProvider, useAppContext };
